<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('company.edit','批量修改班组类型'):$l('company.add','批量修改班组类型')"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('company.labelWidth','130px')">
      <el-form-item label="班组类型" prop="teamType" :rules="$rule.notNull">
        <vm-dict-select v-model="model.teamType" type="teamType"></vm-dict-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <customer-select ref="customerSelect" @select="selectCustomer"></customer-select>
    <teamSelect ref="teamSelect" :checkbox="true" @select="teamSelect"></teamSelect>
  </el-dialog>
</template>
<script>
  import CustomerSelect from "@/views/elevatorUsingCompany/CompanySelect";
  import teamSelect from "@/views/framework/teamSelect";
  export default {
    components: {CustomerSelect,teamSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          teamType:"",
          teams:[],
        },
      };
    },
    methods: {
      open(selectList) {
        const arr = [];
        selectList.forEach(item=>{
          arr.push(item.id);
        });
        this.teams = selectList;
        this.model.realEstateIds=arr;
        this.dialogVisible = true;
        // this.getData();
      },

      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .post("/organization/team/teamType", {teams:this.teams,teamType:this.model.teamType})
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>