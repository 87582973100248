<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑班组':'新增班组'"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('device.labelWidth','80px')">
      <el-form-item label="班组名称" prop="name">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>

      <el-form-item label="所属公司" prop="companyId">
        <el-select v-model="model.companyId" :placeholder="$l('common.enter','请输入')" :disabled="parentId">
          <el-option

            v-for="item in data"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属班组" prop="parentId">
        <el-select v-model="model.parentId" :placeholder="$l('common.enter','请输入')" :disabled="parentId" clearable>
          <el-option

            v-for="item in teamList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班组类型" prop="type">
        <vm-dict-select v-model="model.type" type="teamType"></vm-dict-select>
      </el-form-item>

      <el-form-item label="班组队长" prop="leaderId">
        <el-select v-model="model.leaderId" :filterable="true" :placeholder="$l('common.enter','请输入')">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班组副队长" prop="deputyLeaderId">
        <el-select v-model="model.deputyLeaderId" :filterable="true" :placeholder="$l('common.enter','请输入')">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="按需保养间隔日期" prop="remark">
        <el-input-number v-model="model.serviceInterval" :max="90" :min="0" :placeholder="$l('common.enter','请输入')"></el-input-number>
      </el-form-item>
      <el-form-item label="按规保养间隔日期" prop="remark">
        <el-input-number v-model="model.regulationInterval" :max="15" :min="0" :placeholder="$l('common.enter','请输入')"></el-input-number>
      </el-form-item>

      <el-form-item label="维修人数" prop="remark">
        <el-input-number v-model="model.repairNum" :max="model.employeeNum" :min="0" :placeholder="$l('common.enter','请输入')"></el-input-number>
      </el-form-item>
      <el-form-item label="平均每人维保人数" prop="remark">
        <el-input-number v-model="model.perMaintain" :min="0" :placeholder="$l('common.enter','请输入')"></el-input-number>
      </el-form-item>
      <el-form-item label="备注信息" prop="remark">
        <el-input v-model="model.remark" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
  // import loginUtil from "@/util/loginUtil";

  import loginUtil from "@/util/loginUtil";

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        companyId: loginUtil.getCompanyCode(),
        parentId: loginUtil.getUserInfo().employee.parentId,
        model: {
          companyId: loginUtil.getCompanyCode(),
        },
        data: [],
        options:[],
        width:200,
        teamList:[],
      };

    },
    mounted() {
      // const parentId = loginUtil.getUserInfo().employee.parentId;
      this.$http.get("organization/employee/list",{companyId : loginUtil.getCompanyCode()}).then(res => {
        this.options = res;
      });
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
        this.getTreeList();
        this.getTeamList();
      },
      getTeamList() {
        this.$http.get("/organization/team/list",{companyId : loginUtil.getCompanyCode(), parentIdIsNull:true}).then(res => {
          this.teamList = res;

        });
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`/organization/team/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
            this.model.companyId = loginUtil.getCompanyCode();
          });
          this.$http.get("organization/employee/list",{companyId : loginUtil.getCompanyCode()}).then(res => {
            this.options = res;
          });
        }
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("organization/team", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      getTreeList() {

        let http =  this.$http.get("/organization/company/page",{pageSize:100});
        http.then(data => {
          this.data = data.records.filter(item=>item.id===loginUtil.getCompanyCode()||item.parentId===loginUtil.getCompanyCode());
        }).catch(() => {
          this.status = "error";
        });
      },
      formatArr(arr,list) {
        return arr.map(item=>{
          if (item.children instanceof Array) {
            this.formatArr(item,list);
          }else {
            list.push(item);
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
