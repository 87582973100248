<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="/organization/team/page"
      @selection-change="handleSelectionChange">
      <el-button
        v-if="$auth('班组管理新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open()">
        {{$l("common.add", "新增")}}
      </el-button>
      <el-button
        v-if="$auth('班组管理批量修改班组类型')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.teamMerge.open(selectList)">
        {{$l("common.add", "批量修改班组类型")}}
      </el-button>

      <el-button
        v-if="$auth('班组管理导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="班组名称">
            <el-input v-model="filter.name" type="iotDeviceModel"></el-input>
          </vm-search>
        </div>
      </template>

      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
            :data="props.row.subTeams"
            label-position="left"
            class="demo-table-expand">
            <el-table-column align="scope.row.center" type="index" width="50"></el-table-column>

            <el-table-column prop="name" label="班组名称" align="center"></el-table-column>
            <el-table-column prop="typeDesc" label="班组类型" align="center"></el-table-column>
            <el-table-column prop="companyName" label="公司名称" align="center"></el-table-column>
            <el-table-column prop="leaderName" label="班组队长" align="center"></el-table-column>
            <el-table-column prop="elevatorQuantity" label="电梯台量" align="center"></el-table-column>
            <el-table-column prop="repairNum" label="维修人数" align="center"></el-table-column>
            <el-table-column prop="perMaintain" label="人均维保台量" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
            <el-table-column prop="updateBy" label="修改人" align="center"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" align="center" width="90">
              <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
                <template slot-scope="scope">
                  <el-button v-if="$auth('班组管理编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
                  <el-button v-if="$auth('班组管理详情')" type="primary" @click="$refs.teamDetail.open(scope.row)">{{$l("common.edit", "详情")}}</el-button>
                  <el-button v-if="$auth('班组管理删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="班组名称" align="center"></el-table-column>
      <el-table-column prop="typeDesc" label="班组类型" align="center"></el-table-column>
      <el-table-column prop="companyName" label="公司名称" align="center"></el-table-column>
      <el-table-column prop="leaderName" label="班组队长" align="center"></el-table-column>
      <el-table-column prop="realEstates" label="楼盘" align="center">
        <template slot-scope="scope">
          <el-popover
            placement="top"
            width="400"
            trigger="hover">
            <span v-for="(item,index) in scope.row.realEstates" :key="index">{{item.name}} </span>

            <el-button slot="reference">楼盘</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorQuantity" label="电梯台量" align="center"></el-table-column>
      <el-table-column prop="repairNum" label="维修人数" align="center"></el-table-column>
      <el-table-column prop="perMaintain" label="人均维保台量" align="center"></el-table-column>
      <el-table-column prop="parentName" label="所属班组" align="center"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
      <el-table-column prop="updateBy" label="修改人" align="center"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间" align="center" width="90">
      </el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="$auth('班组管理编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('班组管理详情')" type="primary" @click="$refs.teamDetail.open(scope.row)">{{$l("common.edit", "详情")}}</el-button>
          <el-button v-if="$auth('班组管理删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <teamDetail ref="teamDetail" @save-success="getList(-1)"></teamDetail>
    <team-merge ref="teamMerge" @save-success="getList(-1)"></team-merge>
    <el-dialog :visible.sync="visible" width="800px">
      <vm-drag-upload-file
        url="excelTemplate/team.xlsx"
        action="organization/team/import"
        name="班组导入"
        download-name="班组模板.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
      <vm-drag-upload-file
        url="excelTemplate/employeeTeam.xlsx"
        action="organization/employee-team/import"
        name="班组成员导入"
        download-name="班组成员模板.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>

<script>
  import EditPage from "./teamEdit.vue";
  import teamDetail from "@/views/framework/teamDetail";
  import TeamMerge from "@/views/framework/teamMerge";
  import loginUtil from "@/util/loginUtil";
  export default {
    components: {TeamMerge, EditPage,teamDetail},
    data() {
      return {
        loading:false,
        data: [],
        filter: {
          // parentIdIsNull:true,
        },
        defaultProps: {
          children: "children",
          label: "label",
        },
        dialogVisible:false,
        width:200,
        selectList:[],
        visible:false,
      };
    },
    computed:{
      formatData:function() {
        console.log(this.filter,11);
        return [];
      },
    },
    created() {
      this.filter.companyId = loginUtil.getCompanyCode();

    },
    mounted() {
      this.getList(1);
    },
    methods: {
      handleSelectionChange(val) {
        this.selectList = val;
      },
      getList(pageNum) {
        console.log(145,this.filter);
        this.$refs.vmTable.getList(pageNum);
        this.visible = false;
      },
      handleNodeClick(data) {
        console.log(data,89);
        this.filter={...this.filter,companyId:data.id};
        this.$refs.vmTable.getList(0,{companyId:data.id});
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/organization/team/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>

<style scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1200px);
  max-height: 750px;
  overflow-y: auto;
}
</style>
