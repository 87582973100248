<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'班组人员':'班组人员'"
    width="1600px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <vm-table
      ref="name"
      :filter.sync="filter"
      url="organization/employee-team/page">
      <!--      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open()">-->
      <!--        {{$l("common.add", "新增")}}-->
      <!--      </el-button>-->
      <vm-upload-file slot="toolbar" action="organization/team/import" @save-success="getList(-1)"></vm-upload-file>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="班组名称">
            <el-input v-model="filter.teamName" type="iotDeviceModel"></el-input>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="teamName" label="班组名称" align="center"></el-table-column>
      <el-table-column prop="employeeName" label="员工姓名" align="center"></el-table-column>
      <el-table-column prop="leaderName" label="是否队长" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.leaderName">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column prop="employeePhone" label="手机号码" align="center"></el-table-column>


      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.leaderName" type="primary" @click="$refs.editPage.open(scope.row.employeeId)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="!scope.row.leaderName" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </el-dialog>
</template>
<script>
  import {getWidth} from "@/util";
  import EditPage from "./EmployeeEdit.vue";

  export default {
    components: {EditPage},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        filter: {
          id: 0,
          no: "",
          source: "",
          isp: "",
          teamName:"",
        },
        model:{},
        data: [],
        width:200,
      };

    },
    mounted() {

    },
    methods: {
      open(row) {
        this.dialogVisible = true;
        this.model.id = row.id;
        this.filter.teamName=row.name;
        this.getList(1);
      },
      getList(pageNum) {
        let time=window.setTimeout(()=>{
          this.$refs.name.getList(pageNum);
          window.clearTimeout(time);
        },200);

      },
      deleteRow(row) {
        console.log(row,86);
        this.$confirm(`确定删除${row.employeeName}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/organization/employee/${row.employeeId}`).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("organization/department", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/organization/company/tree");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
