<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择班组"
    class="small-padding"
    width="1200px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="organization/team/page"
      @selection-change="handleSelectionChange">
      <el-button
        v-if="checkbox"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="onSelectCheckbox">
        {{$l("common.add", "批量选择")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="班组名称">
            <el-input v-model="filter.name" type="iotDeviceModel"></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column
        v-if="checkbox"
        type="selection"
        width="55">
      </el-table-column>

      <el-table-column prop="name" label="班组名称" align="center"></el-table-column>
      <el-table-column prop="typeDesc" label="班组类型" align="center"></el-table-column>

      <el-table-column width="70" :label="$l('common.function','操作')" align="center">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>
  export default {
    components: {},
    props:{
      checkbox:{
        type:Boolean,
        default:false,
      },
    },
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
          index:0,
        },
        senior:false,
        selectList:[],
      };
    },
    methods: {
      open(index) {
        this.filter={};
        this.index = index;
        this.dialogVisible = true;

        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        console.log(109,row);
        this.$emit("select", row,this.index);
      },
      handleSelectionChange(val) {
        this.selectList = val;
      },
      onSelectCheckbox() {
        this.dialogVisible = false;
        this.$emit("select", this.selectList,this.index);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
